@import url("https://fonts.cdnfonts.com/css/trajan-pro");
@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");

.App {
  text-align: center;
  overflow-x: hidden;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f7f7f7;
}

*::-webkit-scrollbar-thumb {
  background-color: #720840;
  border-radius: 20px;
  border: 2px solid #f7f7f7;
}

/*********************/

.mainText {
  font-family: "Share Tech Mono", monospace;
}

.gb {
  background-image: url("../../public/rsc/img/seiglieWB.jpeg");
}

.navigation_Wrapper {
  background-color: #f7f7f7;
  height: 100px;
  justify-content: center;
  border-bottom: 2px solid #720840;
  box-shadow: 0px 3px 10px 1px #720840;
}

.offcanvas{
  width: 280px !important;
}

.menu_Head {
  background-image: url("../../public/rsc/img/banner_color_133.png");
  background-size: fill;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  height: 162px;
  margin-bottom: 1.5rem;
}

.close-container {
  position: relative;
  margin: auto;
  width: 20px;
  height: 50px;

  cursor: pointer;
}

.leftright {
  height: 4px;
  width: 20px;
  position: absolute;
  margin-left: 110px;
  margin-top: -30px;
  background-color: #720840;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  height: 4px;
  width: 20px;
  position: absolute;
  margin-left: 110px;
  margin-top: -30px;
  background-color: #720840;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}

label {
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright {
  transform: rotate(-45deg);
  background-color: #720840;
}
.close-container:hover .rightleft {
  transform: rotate(45deg);
  background-color: #720840;
}
.close-container:hover label {
  opacity: 1;
}

.menu_Close {
  display: flex;
  align-self: flex-start;
  border: 0;
  background-color: transparent;
  color: #720840;
}

.nav_Wrapper {
  display: inline-grid;
  align-content: center;
  padding: 0;
}

.navWrap {
  display: flexbox;
  flex-direction: column;
  align-items: center;
}

.navItem {
  padding: 1rem;
  list-style-type: none;
}

.navLink {
  text-decoration: none;
}
.navItem:hover {
  color: #720840;
}

.RRSS_list {
  list-style-type: none;
}

.RRSS_list:last-child {
  margin-bottom: 0;
}

.RRSS_link {
  margin-bottom: 0;
  text-decoration: none;
  padding: 1rem;
  font-size: 1.2rem;
  text-align: left;
  color: black;
}

.RRSS_link:first-child {
  padding-top: 0;
  margin-top: 0;
}

.RRSS_link:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.RRSS_link:hover {
  color: #720840;
}

.RRSS_btn {
  background-image: url("../../public/rsc/img/board1.png");
  background-position-x: 70px;
  background-position-y: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  color: #720840;
  font-weight: 600;
  justify-content: center;
}

.accordion {
  --bs-accordion-active-color: #720840;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-border-color: #720840;
  --bs-accordion-btn-focus-border-color: #720840 !important;
}
.accordion-button:focus {
  border-color: #720840 !important;
}
.about_wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-top: 4.5rem;
 padding-right: 2rem;
}

.about_Hola {
  font-size: 5rem;
  color: #720840;
}

.text_Intro {
  font-size: 2.2rem;
}

.text_normal {
  font-size: 2rem;
}

.about_section {
  margin-bottom: 8rem;
}

.about_quote {
  place-content: center;
}

q {
  border: 2px solid #720840;
  border-radius: 0.5rem;
  padding: 1rem;
  color: #720840;
}

q::before {
  content: "« ";
}
q::after {
  content: " »";
}

.text_Link {
  text-decoration: none;
  font-size: 2rem;
  color: black;
}

.text_Link:hover {
  cursor: pointer;
  color: black;
}

.colab_Title {
  margin-bottom: 2rem;
}
.contact_text {
  margin-bottom: 5rem;
}

.text_hablamos {
  margin-top: 3rem;
}

#tech {
  border-bottom: 2px solid #720840;
  border-top: 1px solid #720840;
  box-shadow: 0px 3px 10px 1px #720840;
}

.tech_Title,
.colab_Title {
  font-family: "Share Tech Mono", monospace;
  margin-top: 5rem;
  color: #720840;
}

.tech_wrapper {
  margin-top: 5rem;
}

.tech_Holder {
  justify-content: space-evenly;
  display: flex;
  margin-bottom: 3rem;
}

.tech_img {
  width: 8rem;
  height: 7rem;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
}

.colab_Title {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

#colab {
  background-color: #f7f7f7;
  border-top: 1px solid #720840;
  box-shadow: 0px 3px 10px 1px #720840;
}

.colab_Wrapper {
  margin-top: 2rem;
}

.colab_img {
  width: 12rem;
  height: 11rem;
  border-radius: 0.5rem;
  padding: 2rem;
}

.colab_ImgLink:hover {
  cursor: pointer;
}

.colab_Holder {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.contact_Wrapper {
  width: 70%;
  margin-bottom: 5rem;
}

.contact_accordion {
  align-self: center;
}

.contact_Icon {
  padding: 0.5rem;
}

.contacto_Img {
  width: 20rem;
  height: auto;
}

.Seiglie_Footer {
  background-color: #f7f7f7;
  font-family: "Share Tech Mono", monospace;
  font-size: 8pt;
  
  padding-top: 1rem;
  
}

.footer_Link {
  text-decoration: none;
  color: black;
}

.footer_Link:hover {
  cursor: pointer;
  color: #720840;
}

.footer_Ul {
  padding: 0;
}

.footer_List {
  list-style-type: none;
}

.footer_Logo {
  width: 80px;
  height: 80px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  
}

.tech_section {
  background-color: #f7f7f7;
  padding: 2rem;
}

/**** BLOG *******/

.metele {
  margin: 3rem 0;
  font-family: 'Courier New', Courier, monospace;
  background-color: #720840;
  padding: 0.5rem;
  color: white;
}
.tab-wrapper{
  overflow-x: scroll;
}
.tab_wrap {
  place-content: center;
  min-inline-size: max-content;
  
}

.link_code,
.link_idea,
.link_pojetcs, .link_tech {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
  width: 200px;
  color: #720840;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 1rem;
  margin-bottom: 2rem !important;
}

.link_code {
  background-image: url("../../public/rsc/img/blog/code.jpg") !important;
}

.link_pojetcs {
  background-image: url("../../public/rsc/img/blog/charts.jpg") !important;
}

.link_idea {
  background-image: url("../../public/rsc/img/blog/idea1.jpg") !important;
}

.link_tech{
  background-image: url("../../public/rsc/img/blog/tech.jpg") !important;
}

.link_text {
  background-color: #f7f7f7;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-family: 'Courier New', Courier, monospace;
}
.link_text:hover, .link_code:hover,
.link_idea:hover,
.link_pojetcs:hover, .link_tech:hover {
  box-shadow: 0px 0px 5px 3px #720840;
  color: black;
}

.tab-content-wrapper{
  height: max-content;
  
}

.coffee{
  height: 50px; 
  width: 160px
}

.proximamente{
    display: inline-grid;
    place-content: center;
    vertical-align: middle;
    word-wrap: break-word;
    height: 30vh;
    font-size: 3rem;
    font-family: 'Courier New', Courier, monospace;
}